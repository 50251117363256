import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Spin } from 'antd';

import * as userApi from '../../../../../../api/user';

const FormItem = Form.Item;

const SubordinateFormModal = (props) => {
  const { visible, selectedSubordinateId, afterSubmit } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const isCreateNewRecord = selectedSubordinateId === undefined;

  const getSubordinate = (subordinateId) => {
    setIsLoading(true);
    userApi
      .getSubordinate(subordinateId)
      .then((res) => res.json())
      .then((json) => {
        form.setFieldsValue({ name: json.name, username: json.username });
        setIsLoading(false);
      });
  };
  const handleSubmit = (values) => {
    setIsLoading(true);

    (selectedSubordinateId
      ? userApi.updateSubordinate(selectedSubordinateId, values)
      : userApi.createSubordinate(values)
    )
      .then((res) => res.json())
      .then((json) => {
        setIsLoading(false);
        afterSubmit();
      });
  };

  useEffect(() => {
    if (visible) {
      if (selectedSubordinateId !== undefined) {
        getSubordinate(selectedSubordinateId);
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
    }
  }, [visible]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  return (
    <Modal
      centered
      forceRender
      className="FormModal SubordinateFormModal"
      width="60%"
      style={{ pointerEvents: isLoading ? 'none' : '' }}
      maskClosable={!isLoading}
      title={null}
      footer={null}
      {...props}
    >
      <Spin spinning={isLoading} tip="Loading...">
        <Form form={form} initialValues={{ isActive: true }} onFinish={(values) => handleSubmit(values)}>
          <FormItem
            {...formItemLayout}
            label="Display Name"
            name="name"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Input />
          </FormItem>
          <FormItem {...formItemLayout} label="Email" name="email">
            <Input />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Username"
            name="username"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Input />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={isCreateNewRecord ? 'Password' : 'New Password'}
            name="password"
            rules={[{ required: isCreateNewRecord, message: 'This field is required.' }]}
          >
            <Input type="password" />
          </FormItem>
          <FormItem>
            <Button type="primary" size="large" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SubordinateFormModal;
