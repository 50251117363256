import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Select, Spin, Switch } from 'antd';
import _ from 'lodash';

import * as externalSensorApi from '../../../../../../../../api/externalSensor';
import './style.scss';

const FormItem = Form.Item;
const { Option } = Select;

const ExternalSensorFormModal = (props) => {
  const { visible, belimoUserId, selectedProjectId, zoneList, selectedExternalSensorId, afterSubmit } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [topic, setTopic] = useState();
  const isCreateNewExternalSensor = selectedExternalSensorId === undefined;

  const getExternalSensor = (externalSensorId) => {
    setIsLoading(true);
    externalSensorApi
      .getExternalSensor(externalSensorId)
      .then((res) => res.json())
      .then((json) => {
        form.setFieldsValue({
          name: json.name,
          zoneId: json.zoneId,
          isControlled: json.isControlled,
        });
        setTopic(json.topic);
        setIsLoading(false);
      });
  };
  const handleSubmit = (values) => {
    setIsLoading(true);
    (selectedExternalSensorId
      ? externalSensorApi.updateExternalSensor(selectedExternalSensorId, values)
      : externalSensorApi.createExternalSensor({ projectId: selectedProjectId, ...values })
    )
      .then((res) => res.json())
      .then((json) => {
        setIsLoading(false);
        afterSubmit();
      });
  };

  useEffect(() => {
    if (visible) {
      if (selectedExternalSensorId !== undefined) {
        getExternalSensor(selectedExternalSensorId);
      } else {
        setTopic();
        form.resetFields();
      }
    } else {
      setTopic();
      form.resetFields();
    }
  }, [visible]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  return (
    <Modal
      centered
      forceRender
      className="FormModal ExternalSensorFormModal"
      width="60%"
      style={{ pointerEvents: isLoading ? 'none' : '' }}
      maskClosable={!isLoading}
      title={null}
      footer={null}
      {...props}
    >
      <Spin spinning={isLoading} tip="Loading...">
        {!isCreateNewExternalSensor && (
          <div className="mqtt-instruction-box">
            <h2>MQTT Setup Instruction</h2>
            <table>
              <tr>
                <td>URI</td>
                <td>mqtt://broker.fillet.io</td>
              </tr>
              <tr>
                <td>Host</td>
                <td>8000</td>
              </tr>
              <tr>
                <td>Username</td>
                <td>{_.toLower(belimoUserId)}</td>
              </tr>
              <tr>
                <td>Password</td>
                <td>jf84jADjrg94MF+adg=I4Iimd</td>
              </tr>
              <tr>
                <td>Topic</td>
                <td>{topic}</td>
              </tr>
            </table>
          </div>
        )}
        <Form form={form} initialValues={{ isControlled: true }} onFinish={(values) => handleSubmit(values)}>
          <FormItem
            {...formItemLayout}
            label="External Sensor Name"
            name="name"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Input />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Zone"
            name="zoneId"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Select>
              {_.map(zoneList, (zone) => (
                <Option key={zone.value} value={zone.value}>
                  {zone.label}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Used for AI control?"
            name="isControlled"
            valuePropName="checked"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Switch />
          </FormItem>
          <FormItem>
            <Button type="primary" size="large" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ExternalSensorFormModal;
