import _ from 'lodash';

export default (obj = {}) => {
  const queries = _.compact(Object.keys(obj).map((key) => (!_.isUndefined(obj[key]) ? key + '=' + obj[key] : '')));
  if (queries.length !== 0) {
    return `?${queries.join('&')}`;
  } else {
    return '';
  }
};
