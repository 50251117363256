import React, { useState, useEffect } from 'react';
import { AutoComplete, Button, Descriptions, Form, Input, Popconfirm, Radio, Spin } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import * as configApi from '../../api/guest';
import * as ticketActionEmailApi from '../../api/ticketActionEmail';
import './style.scss';

const DescriptionsItem = Descriptions.Item;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const TicketActionEmailPage = (props) => {
  const { match } = props;
  const { ticketActionEmailId } = match.params;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestTypes, setRequestTypes] = useState([]);
  const [ticketActionEmail, setTicketActionEmail] = useState({});
  const [inputMessage, setInputMessage] = useState('');
  const { createdAt, ticket } = ticketActionEmail;
  const { zoneName, requestType, contactNumber, message } = ticket || {};
  const targetRequestType = _.find(requestTypes, { value: requestType });

  const MESSAGE_MAX_LENGTH = 30;
  const presetResponseOptions = _.map(['Request received.', 'Temperature adjusted.'], (data) => ({
    label: data,
    value: data,
  }));

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  const getTicketActionEmail = (ticketActionEmailId) => {
    ticketActionEmailApi
      .getTicketActionEmail(ticketActionEmailId)
      .then((res) => res.json())
      .then((json) => {
        if (!json.err) {
          setTicketActionEmail(json);
        }
      });
  };

  const fetchRequestTypes = () => {
    configApi
      .getRequestTypes()
      .then((res) => res.json())
      .then((json) => setRequestTypes(json));
  };

  const handleSubmit = (values) => {
    setIsLoading(true);

    ticketActionEmailApi
      .quickRespondTicket(ticketActionEmailId, values)
      .then((res) => res.json())
      .then((json) => {
        setIsLoading(false);
        setIsSubmitted(true);
      });
  };

  useEffect(() => {
    fetchRequestTypes();
    getTicketActionEmail(ticketActionEmailId);
  }, [ticketActionEmailId]);

  return (
    <Spin spinning={isLoading} tip="Processing...">
      <div className="TicketActionEmailPage">
        <div className="form-container">
          {isSubmitted ? (
            <p className="message">Ticket responded. This link will not work anymore.</p>
          ) : !_.isEmpty(ticketActionEmail) ? (
            <div>
              <Descriptions title="Ticket Detail" bordered column={1} size="small">
                <DescriptionsItem label="Date">{moment(createdAt).format('DD/MM/YYYY')}</DescriptionsItem>
                <DescriptionsItem label="Zone Name">{zoneName || ''}</DescriptionsItem>
                <DescriptionsItem label="Contact Number">{contactNumber || '--'}</DescriptionsItem>
                <DescriptionsItem label="Request Type">
                  {targetRequestType ? targetRequestType.label : requestType}
                </DescriptionsItem>
                <DescriptionsItem label="Message">{message}</DescriptionsItem>
              </Descriptions>
              <div className="form-section">
                <p className="sub-title">Quick Respond</p>
                <Form form={form} onFinish={handleSubmit} initialValues={{ isCompleted: false }}>
                  <FormItem
                    {...formItemLayout}
                    label="Message"
                    name="message"
                    rules={[{ required: true, message: 'This field is required.' }]}
                    labelAlign="left"
                  >
                    <AutoComplete options={presetResponseOptions} onChange={(value) => setInputMessage(value)}>
                      <Input maxLength={MESSAGE_MAX_LENGTH} suffix={`${inputMessage.length}/${MESSAGE_MAX_LENGTH}`} />
                    </AutoComplete>
                  </FormItem>
                  <FormItem
                    {...formItemLayout}
                    label="Close this request?"
                    name="isCompleted"
                    rules={[{ required: true, message: 'This field is required.' }]}
                    labelAlign="left"
                  >
                    <RadioGroup>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </RadioGroup>
                  </FormItem>
                  <div className="footer">
                    <Popconfirm
                      title="Once you have submitted, this link will not work anymore. Are you sure to submit?"
                      onConfirm={() => form.submit()}
                    >
                      <Button type="primary" size="large" htmlType="submit">
                        Submit
                      </Button>
                    </Popconfirm>
                  </div>
                </Form>
              </div>
            </div>
          ) : (
            <p className="message">Not a valid link.</p>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default TicketActionEmailPage;
