import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';
import cx from 'classnames';
import numeral from 'numeral';
import _ from 'lodash';

import * as sensorApi from '../../../../../../../../api/sensor';
import './style.scss';

const SensorPopup = (props) => {
  const { sensor, active } = props;
  const sensorParams = useSelector((state) => state.config.sensorParams);
  const { id, name, left, top } = sensor;
  const [data, setData] = useState({});

  const getSensorData = () => {
    sensorApi
      .getSensorData(id)
      .then((res) => res.json())
      .then((json) => setData(json));
  };

  const content = () => {
    return (
      <div>
        <h3>{name}</h3>
        {_.map(data, (value, key) => {
          const param = _.find(sensorParams, { value: key }) || {};
          return (
            <div key={key}>
              <b>{param.label}:</b> {numeral(value).format('0[.]00')}
              {param.unit}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Popover
      className="SensorPopup"
      onVisibleChange={(visible) => {
        if (visible && active) {
          getSensorData();
        }
      }}
      content={content()}
    >
      <div className={cx('sensor-block', { active: active })} style={{ left, top }}></div>
    </Popover>
  );
};

export default SensorPopup;
