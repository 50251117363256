import { combineReducers } from 'redux';

import UserReducer from './user';
import ConfigReducer from './config';

const rootReducer = combineReducers({
  user: UserReducer,
  config: ConfigReducer,
});

export default rootReducer;
