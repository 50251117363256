import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, InputNumber, Layout, notification } from 'antd';

import * as configApi from '../../../../api/config';
import './style.scss';

const FormItem = Form.Item;

const TheresholdsSettingsPage = () => {
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  const getConfigs = () => {
    configApi
      .getSetpointLimit(selectedProjectId)
      .then((res) => res.json())
      .then((json) => {
        form.setFieldsValue(JSON.parse(json.value));
      });
  };

  const handleSubmit = (values) => {
    configApi
      .updateSetpointLimit(selectedProjectId, values)
      .then((res) => res.json())
      .then((json) => {
        notification.success({
          message: 'Update Succeed',
        });
        getConfigs();
      });
  };

  useEffect(() => {
    if (selectedProjectId !== undefined) {
      getConfigs();
    }
  }, [selectedProjectId]);

  return (
    <Layout>
      <div className="TheresholdsSettingsPage">
        <div className="main-section">
          <h2>Thresholds Settings</h2>
          <Form form={form} onFinish={(values) => handleSubmit(values)}>
            <FormItem>
              <h3>Setpoint Limit</h3>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Lower Limit"
              name="lowerLimit"
              rules={[{ required: true, message: 'This field is required.' }]}
            >
              <InputNumber />
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Upper Limit"
              name="upperLimit"
              rules={[{ required: true, message: 'This field is required.' }]}
            >
              <InputNumber />
            </FormItem>
            <FormItem className="submit-btn">
              <Button htmlType="submit" type="primary">
                Update
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default TheresholdsSettingsPage;
