import React from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';

import './style.scss';

const SelectProjectPage = () => {
  const userName = useSelector((state) => state.user.username);
  return (
    <Layout className="SelectProjectPage">
      <div className="project-selector-section">
        <h1>Welcome back, <br/>{userName}.</h1>
        <h2>Please select your project and dashboard mode first.</h2>
      </div>
    </Layout>
  );
};

export default SelectProjectPage;
