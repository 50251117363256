import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Layout, notification } from 'antd';

import * as userApi from '../../../../api/user';
import './style.scss';

const FormItem = Form.Item;

const UserSettingPage = () => {
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  const handleSubmit = (values) => {
    userApi
      .changePaswword(values)
      .then((res) => res.json())
      .then((json) => {
        notification.success({
          message: 'Update Succeed',
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Update failed',
        });
      });
  };

  return (
    <Layout>
      <div className="UserSettingPage">
        <div className="main-section">
          <h2>User Setting</h2>
          <Form form={form} onFinish={(values) => handleSubmit(values)}>
            <FormItem>
              <h3>Change Password</h3>
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="Current Password"
              name="password"
              rules={[{ required: true, message: 'This field is required.' }]}
            >
              <Input type="password" />
            </FormItem>
            <FormItem
              {...formItemLayout}
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: 'This field is required.' }]}
            >
              <Input type="password" />
            </FormItem>
            <FormItem className="submit-btn">
              <Button htmlType="submit" type="primary">
                Update
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default UserSettingPage;
