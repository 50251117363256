import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './style.scss';

const LoadingScreen = (props) => {
  const { loadingText } = props;
  const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

  return (
    <div className="LoadingScreen">
      <Spin size="large" tip={loadingText} indicator={antIcon} />
    </div>
  );
};

export default LoadingScreen;
