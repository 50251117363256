export const setRequestHistory = (requests) => localStorage.setItem('requestHistory', JSON.stringify(requests));
export const getRequestHistory = () => {
  try {
    return JSON.parse(localStorage.getItem('requestHistory')) || {};
  } catch (error) {
    return {};
  }
};
export const removeRequestHistory = () => localStorage.removeItem('requestHistory');
export const setTicketHistory = (requests) => localStorage.setItem('ticketHistory', JSON.stringify(requests));
export const getTicketHistory = () => {
  try {
    return JSON.parse(localStorage.getItem('ticketHistory')) || {};
  } catch (error) {
    return {};
  }
};
export const removeTicketHistory = () => localStorage.removeItem('ticketHistory');