import React, { useState, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useSelector } from 'react-redux';
import { Tabs, Table, Tooltip } from 'antd';
import { SolutionOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

import { getWSUrl } from '../../../../../../utils/ws';
import { getJWT } from '../../../../../../utils/jwt';

import * as ticketApi from '../../../../../../api/ticket';
import IrreversiblePopconfirmWrapper from '../../../../../../components/IrreversiblePopconfirmWrapper';
import TicketCard from './components/TicketCard';
import TicketCardModal from './components/TicketCardModal';
import './style.scss';

const TabPane = Tabs.TabPane;

const WS_URL = getWSUrl();

const TicketsPage = (props) => {
  const userId = useSelector((state) => state.user.id);
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const ticketRequestTypes = useSelector((state) => state.config.requestTypes);
  const [dataSource, setDataSource] = useState([]);
  const [assignedTickets, setAssignedTickets] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState();
  const [isTicketModalVisible, setIsTicketModalVisible] = useState(false);
  const zoneList = _.flatMap(projectData, (floor) =>
    _.flatMap(floor.rooms, (room) => ({
      label: `${room.name}`,
      value: room.id,
    }))
  );
  const unhandledData = _.filter(dataSource, (record) => _.isNull(record.handler));
  const handledData = _.filter(dataSource, (record) => !_.isNull(record.handler) && !record.isCompleted);
  const completedData = _.filter(dataSource, (record) => record.isCompleted);

  const getTickets = () => {
    ticketApi
      .getTickets({ project_id: selectedProjectId })
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setAssignedTickets(_.filter(json, (ticket) => ticket.handlerId === parseInt(userId) && !ticket.isCompleted));
      });
  };

  const handleFollowUpRequestClick = (ticketId) => {
    ticketApi
      .followUpTicket(ticketId)
      .then((res) => res.json())
      .then((json) => getTickets());
  };

  const afterMessageUpdate = () => {
    setIsTicketModalVisible(false);
    getTickets();
  };

  useDeepCompareEffect(() => {
    if (selectedProjectId) {
      const ws = new WebSocket(`${WS_URL}?token=${getJWT()}&project_id=${selectedProjectId}`);
      ws.addEventListener('message', (event) => {
        let json = {};
        try {
          json = JSON.parse(event.data);
          if (json.type === 'NewTicketIn') {
            getTickets();
          } 
        } catch (error) {}
      });
      
      return () => {
        ws.close();
      };
    }
  }, [projectData]);

  useEffect(() => {
    if (selectedProjectId) {
      getTickets();
    }
  }, [selectedProjectId]);

  const columns = [
    {
      title: 'Request at',
      dataIndex: 'createdAt',
      align: 'center',
      render: (value, record) => (
        <div>
          <div>{moment(value).format('DD/MM/YYYY')}</div>
          <div>{moment(value).format('HH:mm')}</div>
        </div>
      ),
    },
    {
      title: 'Zone',
      dataIndex: 'zoneId',
      align: 'center',
      render: (value, record) => {
        const targetZone = _.find(zoneList, { value });

        if (targetZone) {
          return targetZone.label;
        } else {
          return value;
        }
      },
    },
    {
      title: 'Involved Ppl.',
      dataIndex: 'involvedPeople',
      align: 'center',
    },
    {
      title: 'Request Type',
      dataIndex: 'requestType',
      align: 'center',
      render: (requestType) => {
        const targetRequestType = _.find(ticketRequestTypes, { value: requestType }) || {};

        return !_.isEmpty(targetRequestType) ? targetRequestType.label : requestType;
      },
    },
  ];
  const unhandledColumns = columns;
  const handledColumns = columns;

  return (
    <div className="TicketsPage">
      <div className="ticket-section">
        <div className="page-header">
          <h2>All Tickets</h2>
        </div>
        <div className="ticket-table-list">
          <Tabs defaultActiveKey="Unhandled" type="card">
            <TabPane tab={`Unhandled (${unhandledData.length})`} key="Unhandled">
              <Table
                size="middle"
                columns={unhandledColumns}
                dataSource={unhandledData}
                rowKey="id"
                pagination={{
                  pageSize: 5,
                  showSizeChanger: false,
                }}
                scroll={{ x: 640 }}
              />
            </TabPane>
            <TabPane tab={`Processing (${handledData.length})`} key="Processing">
              <Table
                size="middle"
                columns={handledColumns}
                dataSource={handledData}
                rowKey="id"
                pagination={{
                  pageSize: 5,
                  showSizeChanger: false,
                }}
                scroll={{ x: 640 }}
              />
            </TabPane>
            <TabPane tab={`Completed in Past 30 Days (${completedData.length})`} key="Completed">
              <Table
                size="middle"
                columns={handledColumns}
                dataSource={completedData}
                rowKey="id"
                pagination={{
                  pageSize: 5,
                  showSizeChanger: false,
                }}
                scroll={{ x: 640 }}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
      <TicketCardModal
        zoneList={zoneList}
        selectedTicketId={selectedTicketId}
        visible={isTicketModalVisible}
        onCancel={(e) => setIsTicketModalVisible(false)}
        afterSubmit={() => afterMessageUpdate()}
      />
    </div>
  );
};

export default TicketsPage;
