import {
  UPDATE_USER_DATA,
  LOGOUT,
  UPDATE_AVAILABLE_PROJECTS,
  UPDATE_SELECTED_PROJECT,
  UPDATE_AVAILABLE_FLOORS,
  UPDATE_SELECTED_FLOOR,
  UPDATE_PROJECT_DATA,
  UPDATE_PROJECT_DATA_LOADING_STATUS,
  UPDATE_FILL_DEVICES,
} from '../actionTypes';

const INITIAL_STATE = {
  id: undefined,
  belimoUserId: undefined,
  username: '',
  isBelimoUser: false,
  availableProjects: [],
  selectedProjectId: undefined,
  availableFloors: [],
  selectedFloorId: undefined,
  projectData: [],
  isLoadingProjectData: false,
  fillDevices: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return {
        ...state,
        id: action.payload.id,
        belimoUserId: action.payload.belimoUserId,
        username: action.payload.username,
        isBelimoUser: action.payload.isBelimoUser,
      };
    case UPDATE_AVAILABLE_PROJECTS:
      return {
        ...state,
        availableProjects: action.payload,
      };
    case UPDATE_SELECTED_PROJECT:
      return {
        ...state,
        selectedProjectId: action.payload,
      };
      case UPDATE_AVAILABLE_FLOORS:
        return {
          ...state,
          availableFloors: action.payload,
        };
      case UPDATE_SELECTED_FLOOR:
        return {
          ...state,
          selectedFloorId: action.payload,
        };
    case UPDATE_PROJECT_DATA:
      return {
        ...state,
        projectData: action.payload,
      };
    case UPDATE_PROJECT_DATA_LOADING_STATUS:
      return {
        ...state,
        isLoadingProjectData: action.payload,
      };
    case UPDATE_FILL_DEVICES:
      return {
        ...state,
        fillDevices: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        id: undefined,
        belimoUserId: undefined,
        username: '',
        isBelimoUser: false,
        availableProjects: [],
        selectedProjectId: undefined,
        projectData: [],
      };
    default:
      return state;
  }
};
