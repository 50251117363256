import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getQRSticker = (qrStickerId) =>
  fetch(`${API_URL}/guest/qr-stickers/${qrStickerId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const authenticateQRSticker = (qrStickerId, password) =>
  fetch(`${API_URL}/guest/qr-stickers/${qrStickerId}/authenticate`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(password),
  });

export const getRequestsHistory = (queries) =>
  fetch(`${API_URL}/guest/user-requests${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const getTicketsHistory = (queries) =>
  fetch(`${API_URL}/guest/user-tickets${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const createUserRequest = (userRequest) =>
  fetch(`${API_URL}/guest/user-request`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userRequest),
  });

export const createTicket = (ticket) =>
  fetch(`${API_URL}/guest/ticket`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(ticket),
  });

export const getRequestTypes = () =>
  fetch(`${API_URL}/guest/request-types`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });
