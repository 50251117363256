import { getJWT } from '../utils/jwt';

const API_URL = process.env.REACT_APP_API_URL;

export const getTicketActionEmail = (ticketActionEmailId) =>
  fetch(`${API_URL}/ticket-action-emails/${ticketActionEmailId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const quickRespondTicket = (ticketActionEmailId, body) =>
  fetch(`${API_URL}/ticket-action-emails/${ticketActionEmailId}/quick-respond`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(body),
  });
