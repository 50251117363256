const { REACT_APP_WS_URL: WS_URL, NODE_ENV } = process.env;

export const getWSUrl = () => {
  if (NODE_ENV === 'development') {
    return WS_URL;
  } else {
    // var loc = window.location,
    //   wsUrl;
    // if (loc.protocol === 'https:') {
    //   wsUrl = 'wss:';
    // } else {
    //   wsUrl = 'ws:';
    // }
    // wsUrl += '//' + loc.host + WS_URL;

    // return wsUrl;
    return WS_URL;
  }
};
