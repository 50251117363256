import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getFillDevices = (queries) =>
  fetch(`${API_URL}/fill-devices${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getExternalSensor = (externalSensorId) =>
  fetch(`${API_URL}/fill-devices/${externalSensorId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createExternalSensor = (externalSensor) =>
  fetch(`${API_URL}/fill-devices`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(externalSensor),
  });

export const updateExternalSensor = (externalSensorId, externalSensor) =>
  fetch(`${API_URL}/fill-devices/${externalSensorId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(externalSensor),
  });

export const deleteExternalSensor = (externalSensorId) =>
  fetch(`${API_URL}/fill-devices/${externalSensorId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteFillDevices = (externalSensorIds) =>
  fetch(`${API_URL}/fill-devices/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ externalSensorIds }),
  });
