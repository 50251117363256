import {
  UPDATE_USER_DATA,
  LOGOUT,
  UPDATE_AVAILABLE_PROJECTS,
  UPDATE_SELECTED_PROJECT,
  UPDATE_AVAILABLE_FLOORS,
  UPDATE_SELECTED_FLOOR,
  UPDATE_PROJECT_DATA,
  UPDATE_PROJECT_DATA_LOADING_STATUS,
  UPDATE_FILL_DEVICES,
} from '../actionTypes';

export const updateUserData = (json) => ({
  type: UPDATE_USER_DATA,
  payload: json,
});

export const logout = () => ({
  type: LOGOUT,
});

export const updateAvailableProjects = (projects) => ({
  type: UPDATE_AVAILABLE_PROJECTS,
  payload: projects,
});

export const selectProject = (projectId) => ({
  type: UPDATE_SELECTED_PROJECT,
  payload: projectId,
});

export const updateAvailableFloors = (json) => ({
  type: UPDATE_AVAILABLE_FLOORS,
  payload: json,
});

export const selectFloor = (floorId) => ({
  type: UPDATE_SELECTED_FLOOR,
  payload: floorId,
});

export const updateProjectData = (json) => ({
  type: UPDATE_PROJECT_DATA,
  payload: json,
});

export const updateProjectDataLoadingStatus = (isLoading) => ({
  type: UPDATE_PROJECT_DATA_LOADING_STATUS,
  payload: isLoading,
});

export const updateFillDevices = (json) => ({
  type: UPDATE_FILL_DEVICES,
  payload: json,
})
