import { getJWT } from '../utils/jwt';

const API_URL = process.env.REACT_APP_API_URL;

export const getDatapoints = () =>
  fetch(`${API_URL}/config/datapoints`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getRequestTypes = () =>
  fetch(`${API_URL}/config/request-types`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getSensorParams = () =>
  fetch(`${API_URL}/config/sensor-params`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getSetpointLimit = (projectId) =>
  fetch(`${API_URL}/config/${projectId}/setpoint-limit`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const updateSetpointLimit = (projectId, newSetpointLimit) =>
  fetch(`${API_URL}/config/${projectId}/setpoint-limit`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(newSetpointLimit),
  });
