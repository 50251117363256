import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Layout, Modal, Table, Tooltip } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

import * as emailReceiverApi from '../../../../api/emailReceiver';
import TableButtonGroup from '../../../../components/TableButtonGroup';
import IrreversiblePopconfirmWrapper from '../../../../components/IrreversiblePopconfirmWrapper';
import './style.scss';

const FormItem = Form.Item;

const EmailReceiverPage = () => {
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEmailReceiverId, setSelectedEmailReceiverId] = useState();
  const [form] = Form.useForm();

  const getEmailReceivers = () => {
    emailReceiverApi
      .getEmailReceivers({ project_id: selectedProjectId })
      .then((res) => res.json())
      .then((json) => setData(json));
  };

  const getEmailReceiver = (emailRecevierId) => {
    emailReceiverApi
      .getEmailReceiver(emailRecevierId)
      .then((res) => res.json())
      .then((json) => form.setFieldsValue({ email: json.email }));
  };

  const deleteEmailReceiver = (emailReceiverId) => {
    emailReceiverApi
      .deleteEmailReceiver(emailReceiverId)
      .then((res) => res.json())
      .then((json) => {
        getEmailReceivers();
      });
  };

  const batchDeleteEmailReceivers = (emailReceiverIds) => {
    emailReceiverApi
      .batchDeleteEmailReceivers(emailReceiverIds)
      .then((res) => res.json())
      .then((json) => {
        getEmailReceivers();
      });
  };

  const handleSubmit = (values) => {
    (selectedEmailReceiverId === undefined
      ? emailReceiverApi.createEmailReceiver({ projectId: selectedProjectId, ...values })
      : emailReceiverApi.updateEmailReceiver(selectedEmailReceiverId, { projectId: selectedProjectId, ...values })
    )
      .then((res) => res.json())
      .then((json) => {
        setIsModalVisible(false);
        getEmailReceivers();
      });
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left',
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 100,
      render: (value, record) => {
        return (
          <div className="operation-btn-gp">
            <Tooltip title="Edit">
              <EditOutlined
                onClick={() => {
                  setIsModalVisible(true);
                  setSelectedEmailReceiverId(record.id);
                }}
              />
            </Tooltip>
            <IrreversiblePopconfirmWrapper onConfirm={() => deleteEmailReceiver(record.id)}>
              <Tooltip title="Delete">
                <CloseOutlined className="delete-btn" />
              </Tooltip>
            </IrreversiblePopconfirmWrapper>
          </div>
        );
      },
    },
  ];

  const formItemLayout = {
    wrapperCol: {
      span: 24,
    },
  };
  useEffect(() => {
    if (selectedProjectId !== undefined) {
      getEmailReceivers();
    }
  }, [selectedProjectId]);

  useEffect(() => {
    if (isModalVisible) {
      getEmailReceiver(selectedEmailReceiverId);
    } else {
      setSelectedEmailReceiverId(undefined);
      form.resetFields();
    }
  }, [isModalVisible]);

  return (
    <Layout>
      <div className="EmailReceiverPage">
        <div className="main-section">
          <h2>Email Receivers</h2>
          <TableButtonGroup
            enabledButtons={['create', 'delete']}
            onCreate={() => setIsModalVisible(true)}
            onDelete={() => batchDeleteEmailReceivers(selectedRowKeys)}
          />
          <Table
            rowKey="id"
            columns={columns}
            dataSource={data}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
            }}
            pagination={{
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
      <Modal
        centered
        forceRender
        width="60%"
        visible={isModalVisible}
        title={null}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <h3>Enter Email</h3>
        <Form form={form} onFinish={(values) => handleSubmit(values)}>
          <FormItem {...formItemLayout} name="email" rules={[{ required: true, message: 'This field is required.' }]}>
            <Input />
          </FormItem>
          <FormItem style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Modal>
    </Layout>
  );
};

export default EmailReceiverPage;
