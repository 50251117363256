import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import * as authApi from '../api/auth';
import LoadingScreen from '../components/LoadingScreen';
import { updateUserData } from '../redux/actions/user';
import { getJWT, removeJWT } from '../utils/jwt';

const PrivateRoute = (props) => {
  const dispatch = useDispatch();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const { component, ...rest } = props;

  useEffect(() => {
    let ignore = false;
    const authenticate = () => {
      const jwt = getJWT();
      if (jwt) {
        authApi
          .authenticate()
          .then((res) => res.json())
          .then((json) => {
            if (!ignore) {
              dispatch(updateUserData(json.user));
              setIsTokenValid(true);
              setIsAuthenticating(false);
            }
          })
          .catch((err) => {
            console.error(err);
            removeJWT();
            setIsAuthenticating(false);
          });
      } else {
        setIsAuthenticating(false);
      }
    };

    authenticate();

    return () => {
      ignore = true;
    };
  }, [dispatch]);

  if (isAuthenticating) {
    return <LoadingScreen loadingText="Authenticating..." />;
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (isTokenValid ? React.createElement(component, props) : <Redirect to="/login" />)}
      />
    );
  }
};

export default PrivateRoute;
