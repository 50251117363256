import React, { useState } from 'react';
import { Popover } from 'antd';
import numeral from 'numeral';
import _ from 'lodash';

import './style.scss';

const ZonePopup = (props) => {
  const { name, data } = props;
  const { devices } = data;
  const validDevices = devices && devices.length > 0 ? _.filter(devices, 'spaceTemp') : [];

  const content = () => {
    return (
      <div>
        <h3>{name}</h3>
        {_.map(devices, (device) => {
          return (
            <div key={device.id}>
              {device.name}: {device.spaceTemp ? `${numeral(device.spaceTemp).format('0.[00]')} °C` : 'N/A'}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Popover className="ZonePopup" content={content()}>
      <div id={_.kebabCase(name)} className="zone-block">
        {name}
        <br />
        {validDevices.length > 0
          ? `${numeral(_.meanBy(validDevices, (device) => device['spaceTemp'])).format('0.[00]')} °C`
          : 'N/A'}
      </div>
    </Popover>
  );
};

export default ZonePopup;
