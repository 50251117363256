import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getAlerts = (queries) =>
  fetch(`${API_URL}/alerts${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getAlert = (alertId) =>
  fetch(`${API_URL}/alerts/${alertId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createAlert = (alert) =>
  fetch(`${API_URL}/alerts`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(alert),
  });

export const updateAlert = (alertId, alert) =>
  fetch(`${API_URL}/alerts/${alertId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(alert),
  });

export const deleteAlert = (alertId) =>
  fetch(`${API_URL}/alerts/${alertId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteAlerts = (alertIds) =>
  fetch(`${API_URL}/alerts/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ alertIds }),
  });
