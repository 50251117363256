import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ReactECharts from 'echarts-for-react';
import { DatePicker, Select, Tabs } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import * as ticketApi from '../../../../api/ticket';

import './style.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;

const StatisticPage = (props) => {
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const isLoadingProjectData = useSelector((state) => state.user.isLoadingProjectData);
  const [frequencyData, setFrequencyData] = useState([]);
  const [averageRequestTimeData, setAverageRequestTimeData] = useState([]);
  const [range, setRange] = useState([moment().subtract(1, 'months'), moment()]);
  const [resolution, setResolution] = useState('DAY');
  const resolutionInterval = `${resolution}S`;
  const dateFormat = resolution === 'DAY' ? 'DD/MM/YYYY' : 'MM/YYYY';

  const getRequestFrequency = (projectId) => {
    ticketApi
      .getRequestFrequency({
        project_id: projectId,
        from: range[0].toISOString(),
        to: range[1].toISOString(),
        resolution,
      })
      .then((res) => res.json())
      .then((json) => {
        const rangeDiff = Math.ceil(range[1].diff(range[0], resolutionInterval)) + 1;
        let data = { newRequestFrequency: [], completedRequestFrequency: [] };
        _.forEach(_.range(rangeDiff), (i) => {
          const timestamp = range[1]
            .clone()
            .subtract(rangeDiff - (i + 1), resolutionInterval)
            .startOf(resolution)
            .toISOString();

          const targetNewRequestDatapoint = _.find(json.newRequestFrequency, { timestamp });
          const targetCompletedRequestDatapoint = _.find(json.completedRequestFrequency, { timestamp });
          data.newRequestFrequency.push({
            name: timestamp,
            value: [timestamp, targetNewRequestDatapoint ? targetNewRequestDatapoint.count : 0],
          });
          data.completedRequestFrequency.push({
            name: timestamp,
            value: [timestamp, targetCompletedRequestDatapoint ? targetCompletedRequestDatapoint.count : 0],
          });
        });
        setFrequencyData(data);
      });
  };

  const getAverageRequestTime = (projectId) => {
    ticketApi
      .getAverageRequestTime({
        project_id: projectId,
        from: range[0].toISOString(),
        to: range[1].toISOString(),
      })
      .then((res) => res.json())
      .then((json) => {
        const rangeDiff = Math.ceil(range[1].diff(range[0], 'month')) + 1;
        let data = { handledRequestTime: [], completedRequestTime: [] };
        _.forEach(_.range(rangeDiff), (i) => {
          const timestamp = range[1]
            .clone()
            .subtract(rangeDiff - (i + 1), 'months')
            .startOf('month')
            .toISOString();

          const targetHandledRequestTimeDatapoint = _.find(json.handledRequestTime, { timestamp });
          const targetCompletedRequestTimeDatapoint = _.find(json.completedRequestTime, { timestamp });
          data.handledRequestTime.push({
            name: timestamp,
            value: [
              timestamp,
              targetHandledRequestTimeDatapoint
                ? numeral(targetHandledRequestTimeDatapoint.interval / 60).format('0.[00]')
                : 0,
            ],
          });
          data.completedRequestTime.push({
            name: timestamp,
            value: [
              timestamp,
              targetCompletedRequestTimeDatapoint
                ? numeral(targetCompletedRequestTimeDatapoint.interval / 60).format('0.[00]')
                : 0,
            ],
          });
        });
        setAverageRequestTimeData(data);
      });
  };

  const getRequestFrequencyOption = () => {
    const rangeDiff = Math.ceil(range[1].diff(range[0], resolutionInterval)) + 1;
    var option = {
      title: {
        text: `Ticket Numbers Per ${resolution === 'DAY' ? 'Day' : 'Month'}`,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          animation: false,
        },
        formatter: (params, ticket, callback) => {
          let content = `${moment(params[0].name).format(dateFormat)}`;
          _.forEach(params, (param, i) => {
            if (i !== params.length) {
              content += '<br/>';
            }
            content += `${param.marker} <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px;">${param.seriesName}</span> <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900;">${param.value[1]}</span>`;
          });
          return content;
        },
      },
      xAxis: {
        type: 'category',
        splitLine: {
          show: false,
        },
        data: _.map(_.range(rangeDiff), (i) =>
          range[1]
            .clone()
            .subtract(rangeDiff - (i + 1), resolutionInterval)
            .startOf(resolution)
            .toISOString()
        ),
        axisLabel: {
          formatter: (value, index) => moment(value).format(dateFormat),
        },
      },
      yAxis: {
        name: 'Count',
        type: 'value',
        interval: 1,
      },
      series: [
        {
          id: 1,
          name: 'New Requests',
          type: 'line',
          hoverAnimation: false,
          data: frequencyData.newRequestFrequency,
        },
        {
          id: 2,
          name: 'Completed Requests',
          type: 'line',
          hoverAnimation: false,
          data: frequencyData.completedRequestFrequency,
        },
      ],
    };
    return option;
  };

  const getAverageRequestTimeOption = () => {
    const rangeDiff = Math.ceil(range[1].diff(range[0], 'month')) + 1;
    var option = {
      title: {
        text: 'Ticket Service Time (Monthly Averages)',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          animation: false,
        },
        formatter: (params, ticket, callback) => {
          let content = `${moment(params[0].name).format('MM/YYYY')}`;
          _.forEach(params, (param, i) => {
            if (i !== params.length) {
              content += '<br/>';
            }
            content += `${param.marker} <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px;">${param.seriesName}</span> <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900;">${param.value[1]} mins</span>`;
          });
          return content;
        },
      },
      xAxis: {
        type: 'category',
        splitLine: {
          show: false,
        },
        data: _.map(_.range(rangeDiff), (i) =>
          range[1]
            .clone()
            .subtract(rangeDiff - (i + 1), 'months')
            .startOf('month')
            .toISOString()
        ),
        axisLabel: {
          formatter: (value, index) => moment(value).format('MM/YYYY'),
        },
      },
      yAxis: {
        name: 'Time (mins.)',
        type: 'value',
      },
      series: [
        {
          id: 1,
          name: 'Acknowledgement',
          type: 'line',
          hoverAnimation: false,
          data: averageRequestTimeData.handledRequestTime,
        },
        {
          id: 2,
          name: 'Job Completion',
          type: 'line',
          hoverAnimation: false,
          data: averageRequestTimeData.completedRequestTime,
        },
      ],
    };
    return option;
  };

  useDeepCompareEffect(() => {
    if (selectedProjectId) {
      getRequestFrequency(selectedProjectId);
      getAverageRequestTime(selectedProjectId);
    }
  }, [projectData]);

  useDeepCompareEffect(() => {
    if (selectedProjectId) {
      getRequestFrequency(selectedProjectId);
      getAverageRequestTime(selectedProjectId);
    }
  }, [range, resolution]);

  return (
    <div className="StatisticPage">
      <div className="main-section">
        <h2>Statistics</h2>
        <Tabs defaultActiveKey="vav">
          <TabPane tab="Tickets" key="tickets">
            <div className="chart-controller">
              <RangePicker value={range} onChange={(dates) => setRange(dates)} />
            </div>
            <div className="chart-container">
              {
                <ReactECharts
                  style={{ minHeight: '450px', height: '70vh' }}
                  notMerge={true}
                  option={getRequestFrequencyOption()}
                />
              }
              <div className="chart-sub-controller">
                <Select
                  style={{ width: 120 }}
                  placeholder="Resolution"
                  value={resolution}
                  onChange={(value) => setResolution(value)}
                >
                  <Option value="DAY">DAY</Option>
                  <Option value="MONTH">MONTH</Option>
                </Select>
              </div>
            </div>
            <div className="chart-container">
              {
                <ReactECharts
                  style={{ minHeight: '450px', height: '70vh' }}
                  notMerge={true}
                  option={getAverageRequestTimeOption()}
                />
              }
            </div>
          </TabPane>
          <TabPane tab="Devices" key="devices" disabled></TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default StatisticPage;
