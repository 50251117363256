import React, { useEffect, useState } from 'react';
import { Modal, Spin, Tabs } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import * as guestApi from '../../../../api/guest';
import { getRequestHistory, getTicketHistory } from '../../../../utils/localProfile';
import './style.scss';

const { TabPane } = Tabs;

const RequestHistoryModal = (props) => {
  const { isVav, zoneId, visible } = props;
  const [requests, setRequests] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [ticketRequestTypes, setTicketRequestTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState();

  const getRequestIds = () => {
    const requests = getRequestHistory();
    return requests[zoneId] || [];
  };
  const getTicketIds = () => {
    const tickets = getTicketHistory();
    return tickets[zoneId] || [];
  };

  const getRequestsHistory = () => {
    const requestIds = getRequestIds();
    if (requestIds.length > 0) {
      setIsLoading(true);
      guestApi
        .getRequestsHistory({ request_ids: requestIds })
        .then((res) => res.json())
        .then((results) => {
          setRequests(results);
          setIsLoading(false);
        });
    }
  };
  const getTicketsHistory = () => {
    const ticketIds = getTicketIds();
    if (ticketIds.length > 0) {
      setIsLoading(true);
      guestApi
        .getTicketsHistory({ ticket_ids: ticketIds })
        .then((res) => res.json())
        .then((results) => {
          setTickets(results);
          setIsLoading(false);
        });
    }
  };

  const getTicketRequestType = () => {
    setIsLoading(true);
    guestApi
      .getRequestTypes()
      .then((res) => res.json())
      .then((results) => {
        setTicketRequestTypes(results);
        setIsLoading(false);
      });
  };

  const renderRequestsList = () =>
    _.map(requests, (request) => {
      const { id, originalTemp, tempChange, status, createdAt } = request;
      return (
        <div className="request-record" key={id}>
          <div className="request-timestamp">{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
          <div className="request-temp">
            {numeral(originalTemp).format('0.[00]')} °C({numeral(tempChange).format('+0')} °C)
          </div>
          <div className="request-status">{status}</div>
        </div>
      );
    });
  const renderTicketsList = () =>
    _.map(tickets, (ticket) => {
      const { id, requestType, message, ticketProcesses, createdAt } = ticket;
      const targetRequestType = _.find(ticketRequestTypes, { value: requestType }) || {};
      return (
        <div className="request-record" key={id}>
          <div className="request-timestamp">{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
          <div className="request-type">
            <b>Type:</b> {targetRequestType.label}
          </div>
          <div className="request-message">
            <b>Message:</b> {message}
          </div>
          <div className="request-latest-reply">
            <b>Latest Reply:</b> {ticketProcesses.length > 1 ? ticketProcesses[0].message : '(No reply yet)'}
          </div>
        </div>
      );
    });

  const renderEmptyMessage = () => <p>No records.</p>;

  useEffect(() => {
    if (visible) {
      getRequestsHistory();
      getTicketsHistory();
      getTicketRequestType();
    }
  }, [visible]);

  useEffect(() => {
    if (refreshInterval) {
      clearInterval(refreshInterval);
    }
    setRefreshInterval(
      setInterval(() => {
        getRequestsHistory();
        getTicketsHistory();
      }, 10000)
    );
  }, [zoneId]);

  return (
    <Modal
      centered
      forceRender
      className="FormModal RequestHistoryModal"
      width="60%"
      style={{ pointerEvents: isLoading ? 'none' : '' }}
      maskClosable={!isLoading}
      title={null}
      footer={null}
      {...props}
    >
      <Spin spinning={isLoading} tip="Loading...">
        <h2>Past Records in This Zone</h2>
        <Tabs defaultActiveKey={isVav ? 'request' : 'ticket'} size="large">
          {isVav && (
            <TabPane key="request" tab="Request">
              <div className="requests-list">{requests.length > 0 ? renderRequestsList() : renderEmptyMessage()}</div>
            </TabPane>
          )}
          <TabPane key="tiket" tab="Ticket">
            <div className="requests-list">{tickets.length > 0 ? renderTicketsList() : renderEmptyMessage()}</div>
          </TabPane>
        </Tabs>
      </Spin>
    </Modal>
  );
};

export default RequestHistoryModal;
