import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { NavLink } from 'react-router-dom';
import { Table, Tabs } from 'antd';
import { DotChartOutlined, HistoryOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';
import cx from 'classnames';

import { getWSUrl } from '../../../../../../utils/ws';
import { getJWT } from '../../../../../../utils/jwt';
import InfoButton from '../../../../../../components/InfoButton';
import ComfortZoneModal from './components/ComfortZoneModal';
import ZonePopup from './components/ZonePopup';
import SensorPopup from './components/SensorPopup';
import * as projectApi from '../../../../../../api/project';
import * as zoneApi from '../../../../../../api/zone';
import * as sensorApi from '../../../../../../api/sensor';

import './style.scss';

const { TabPane } = Tabs;

const WS_URL = getWSUrl();

const SummaryPage = (props) => {
  const { history } = props;
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const isLoadingProjectData = useSelector((state) => state.user.isLoadingProjectData);
  const [data, setData] = useState([]);
  const [selectedZone, setSelectedZone] = useState({});
  const [sensors, setSensors] = useState([]);
  const [sensorsStatus, setSensorsStatus] = useState({});
  const [sensorInterval, setSensorInterval] = useState();
  const [isComfortZoneModalVisible, setIsComfortZoneModalVisible] = useState(false);

  const zoneDetailList = _.flatMap(projectData, (floor) =>
    _.flatMap(floor.rooms, (room) => ({
      label: `${room.name}`,
      value: room.id,
      isVav: room.type === 'vav',
    }))
  );
  const zoneList = _.map(zoneDetailList, (zone) => zone.value);

  const getZoneStat = (zoneIds) => {
    zoneApi
      .getZoneStat({ zone_ids: zoneIds })
      .then((res) => res.json())
      .then((json) => {
        setData((prevData) =>
          _.flatMap(prevData, (room) => {
            const targetZoneStat = _.find(json, { zoneId: room.id });
            if (targetZoneStat)
              return {
                ...room,
                userDistribution: targetZoneStat.userDistribution,
                pendingZoneRequest: targetZoneStat.pendingZoneRequest,
                successZoneRequest: targetZoneStat.successZoneRequest,
                tickets: targetZoneStat.tickets,
              };
            else return room;
          })
        );
      });
  };

  const getProjectSensors = (projectId) => {
    projectApi
      .getProjectSensors(projectId)
      .then((res) => res.json())
      .then((json) => {
        setSensors(json);
      });
  };

  const getSensorStatus = () => {
    sensorApi
      .getSensorsStatus({ sensor_ids: _.map(sensors, (sensor) => sensor.id) })
      .then((res) => res.json())
      .then((json) => setSensorsStatus(json));
  };

  const updateData = (newDeviceState) => {
    setData((prevData) =>
      _.map(prevData, (room) => {
        return {
          ...room,
          devices: _.map(room.devices, (device) => {
            let deviceData = device;

            if (device.id === newDeviceState.deviceId) {
              deviceData = {
                ...deviceData,
                ...newDeviceState,
              };
            }

            return deviceData;
          }),
        };
      })
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'left',
      fixed: 'left',
      width: 150,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => <div className="child-name">{text}</div>,
    },
    {
      title: 'Avg. Temp (°C)',
      dataIndex: 'devices',
      align: 'center',
      render: (devices, record) => {
        if (record.isRoomSlot) {
          const validDevices = _.filter(devices, 'spaceTemp');
          return validDevices.length > 0
            ? numeral(_.meanBy(validDevices, (device) => device['spaceTemp'])).format('0.[00]')
            : '-';
        }
      },
    },
    {
      title: (
        <div>
          Target Setpoint (°C) <InfoButton title="The target setpoint sent to the system." />
        </div>
      ),
      dataIndex: 'pendingZoneRequest',
      align: 'center',
      render: (zoneRequest, record) => {
        if (record.isRoomSlot && record.isVav) {
          if (zoneRequest) {
            return (
              <div>
                <div>{numeral(parseFloat(zoneRequest.targetTemp)).format('0.[00]')}</div>
                <div className="zone-request-submessage">
                  Last Updated: {moment(zoneRequest.updatedAt).format('HH:mm')}
                </div>
              </div>
            );
          } else {
            return '-';
          }
        } else {
          return '';
        }
      },
    },
    {
      title: (
        <div>
          Last Setpoint Met (°C){' '}
          <InfoButton title="This shows the last target setpoint successfully met by the system." />
        </div>
      ),
      dataIndex: 'successZoneRequest',
      align: 'center',
      render: (zoneRequest, record) => {
        if (record.isRoomSlot && record.isVav) {
          if (zoneRequest) {
            return (
              <div>
                <div>{numeral(parseFloat(zoneRequest.targetTemp)).format('0.[00]')}</div>
                <div className="zone-request-submessage">
                  Last Updated: {moment(zoneRequest.updatedAt).format('HH:mm')}
                </div>{' '}
                {/* <HistoryOutlined /> */}
              </div>
            );
          } else {
            return (
              <div>
                -{' '}
                {/* <Tooltip title="Setpoint History">
                  <HistoryOutlined className="history-table-btn" />
                </Tooltip> */}
              </div>
            );
          }
        } else {
          return '';
        }
      },
    },
    {
      title: (
        <div>
          User Feelings{' '}
          <InfoButton title="This is showing the user distribution(COLD / NORMAL / HOT) in the past hour." />
          <br />
          <div className="user-feelings-title">
            <span className="cold">COLD</span>
            <span className="normal">NORMAL</span>
            <span className="hot">HOT</span>
          </div>
        </div>
      ),
      dataIndex: 'userDistribution',
      align: 'center',
      render: (userDistribution, record) => {
        if (record.isRoomSlot && record.isVav) {
          const groupedDistribution = _.groupBy(userDistribution, 'status');

          return (
            <div className="user-distribution">
              <span className="cold">{groupedDistribution['COLD'] ? groupedDistribution['COLD'].length : 0}</span>
              <span className="normal">{groupedDistribution['NORMAL'] ? groupedDistribution['NORMAL'].length : 0}</span>
              <span className="hot">{groupedDistribution['HOT'] ? groupedDistribution['HOT'].length : 0}</span>
            </div>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: (
        <div>
          Active Tickets <InfoButton title="This is showing the number of unhandled/processing tickets." />
        </div>
      ),
      dataIndex: 'tickets',
      align: 'center',
      render: (value, record) => {
        if (record.isRoomSlot) {
          if (value > 0) {
            return <NavLink to="./tickets">{value}</NavLink>;
          } else {
            return 0;
          }
        }
      },
    },
    {
      title: 'Comfort Zone',
      dataIndex: 'comfortZone',
      align: 'center',
      render: (value, record) => {
        if (record.isRoomSlot && record.isVav) {
          return (
            <DotChartOutlined
              className="comfort-zone-btn"
              onClick={(e) => {
                setSelectedZone({ id: record.id, name: record.name });
                setIsComfortZoneModalVisible(true);
              }}
            />
          );
        } else {
          return '';
        }
      },
    },
  ];

  useDeepCompareEffect(() => {
    if (selectedProjectId) {
      getProjectSensors(selectedProjectId);
      const ws = new WebSocket(`${WS_URL}?token=${getJWT()}&project_id=${selectedProjectId}`);
      ws.addEventListener('message', (event) => {
        let json = {};
        try {
          json = JSON.parse(event.data);
          if (json.type === 'ZoneUpdate' || json.type === 'NewTicketIn') {
            getZoneStat(zoneList);
          } else if (json.type === 'DeviceUpdate') {
            updateData(json);
          }
        } catch (error) {}
      });

      setData(
        _.flatMap(projectData, (floor) => {
          return _.map(floor.rooms, (room) => {
            return {
              isRoomSlot: true,
              isVav: room.type === 'vav',
              id: room.id,
              name: room.name,
              devices: _.map(room.devices, (device) => ({
                id: device.id,
                name: device.name,
                cloudId: device.cloudId,
              })),
            };
          });
        })
      );

      if (zoneList.length > 0) {
        getZoneStat(zoneList);
      }

      return () => {
        ws.close();
      };
    }
  }, [projectData]);

  useDeepCompareEffect(() => {
    if (sensors.length > 0) {
      getSensorStatus();

      clearInterval(sensorInterval);
      setSensorInterval(
        setInterval(() => {
          getSensorStatus();
        }, 60 * 1000)
      );
    }
  }, [sensors]);

  return (
    <div className="SummaryPage">
      <div className="main-section">
        <h2>Summary</h2>
        <Tabs defaultActiveKey="floor-plan">
          <TabPane tab="Floor Plan" key="floor-plan">
            <div className="floor-plan">
              {_.map(zoneDetailList, (zone) => {
                const zoneData = _.find(data, { id: zone.value }) || {};

                return <ZonePopup key={zone.value} name={zone.label} data={zoneData} />;
              })}
              {_.map(sensors, (sensor) => {
                return <SensorPopup key={sensor.id} sensor={sensor} active={sensorsStatus[sensor.id]} />;
              })}
            </div>
          </TabPane>
          <TabPane tab="Setpoints &#38; Tickets" key="table">
            <Table
              rowKey={'id'}
              columns={columns}
              size="small"
              dataSource={data}
              scroll={{ x: 640, y: '65vh' }}
              pagination={false}
              loading={isLoadingProjectData}
            />
          </TabPane>
        </Tabs>
      </div>
      <ComfortZoneModal
        selectedZone={selectedZone}
        visible={isComfortZoneModalVisible}
        onCancel={() => {
          setSelectedZone({});
          setIsComfortZoneModalVisible(false);
        }}
      />
    </div>
  );
};

export default SummaryPage;
