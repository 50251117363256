import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Table, Tooltip } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

import * as alertApi from '../../../../../../api/alert';
import IrreversiblePopconfirmWrapper from '../../../../../../components/IrreversiblePopconfirmWrapper';
import InfoButton from '../../../../../../components/InfoButton';
import TableButtonGroup from '../../../../../../components/TableButtonGroup';
import AlertFormModal from './components/AlertFormModal';
import './style.scss';

const AlertManagementPage = () => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedAlertId, setSelectedAlertId] = useState();
  const [isAlertFormModal, setIsAlertFormModal] = useState(false);
  const datapoints = useSelector((state) => state.config.datapoints);
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const deviceList = _.flatMap(projectData, (building) =>
    _.flatMap(building.floors, (floor) =>
      _.flatMap(floor.rooms, (room) =>
        _.flatMap(room.devices, (device) => ({
          label: `${building.name}/${floor.name}/${room.name}/${device.name}`,
          value: device.cloudId,
        }))
      )
    )
  );

  const getAlerts = () => {
    if (selectedProjectId !== undefined) {
      alertApi
        .getAlerts({ project_id: selectedProjectId })
        .then((res) => res.json())
        .then((json) => {
          setDataSource(json);
        });
    }
  };

  const deleteAlert = (alertId) => {
    alertApi
      .deleteAlert(alertId)
      .then((res) => res.json())
      .then((json) => {
        getAlerts();
      });
  };

  const batchDeleteAlerts = (alertIds) => {
    alertApi
      .batchDeleteAlerts(alertIds)
      .then((res) => res.json())
      .then((json) => {
        getAlerts();
      });
  };

  useEffect(() => getAlerts(), [selectedProjectId]);

  const columns = [
    {
      title: 'Alert Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    },
    {
      title: 'Cooldown Time (mins.)',
      dataIndex: 'cooldown',
      key: 'cooldown',
      align: 'center',
      width: 200,
    },
    {
      title: 'Active?',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 100,
      render: (value, record) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Last Triggered',
      dataIndex: 'lastTriggered',
      key: 'lastTriggered',
      align: 'center',
      width: 150,
      render: (value, record) => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'),
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 100,
      render: (value, record) => {
        const loc = window.location;

        return (
          <div className="operation-btn-gp">
            <Tooltip title="Edit">
              <EditOutlined
                onClick={() => {
                  setIsAlertFormModal(true);
                  setSelectedAlertId(record.id);
                }}
              />
            </Tooltip>
            <IrreversiblePopconfirmWrapper onConfirm={() => deleteAlert(record.id)}>
              <Tooltip title="Delete">
                <CloseOutlined className="delete-btn" />
              </Tooltip>
            </IrreversiblePopconfirmWrapper>
          </div>
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="AlertManagementPage">
        <div className="main-section">
          <h2>
            Threshold Alerts{' '}
            <InfoButton title="You can setup your own alert. Once the alert is triggered, an email will be sent to your email address if you have setup already." />
          </h2>
          <TableButtonGroup
            enabledButtons={['create', 'delete']}
            onCreate={() => setIsAlertFormModal(true)}
            onDelete={() => batchDeleteAlerts(selectedRowKeys)}
          />
          <Table
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
            }}
            pagination={{
              showSizeChanger: false,
            }}
            scroll={{ x: 640 }}
          />
        </div>
      </div>
      <AlertFormModal
        selectedProjectId={selectedProjectId}
        deviceList={deviceList}
        datapoints={datapoints}
        selectedAlertId={selectedAlertId}
        visible={isAlertFormModal}
        onCancel={(e) => {
          setIsAlertFormModal(false);
          setSelectedAlertId(undefined);
        }}
        afterSubmit={() => {
          setIsAlertFormModal(false);
          setSelectedAlertId(undefined);
          getAlerts();
        }}
      />
    </Layout>
  );
};

export default AlertManagementPage;
