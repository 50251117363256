import { COLLAPSE_SIDEBAR, UPDATE_DATAPOINTS, UPDATE_REQUEST_TYPES, UPDATE_SENSOR_PARAMS } from '../actionTypes';

export const collapseSidebar = (isCollapsed) => ({
  type: COLLAPSE_SIDEBAR,
  payload: isCollapsed,
});

export const updateDatapoints = (datapoints) => ({
  type: UPDATE_DATAPOINTS,
  payload: datapoints,
});

export const updateRequestTypes = (requestTypes) => ({
  type: UPDATE_REQUEST_TYPES,
  payload: requestTypes,
});

export const updateSensorParams = (sensorParams) => ({
  type: UPDATE_SENSOR_PARAMS,
  payload: sensorParams,
});
