import React, { useState, useEffect } from 'react';
import { Button, Form, Input, InputNumber, Modal, Select, Spin, Switch } from 'antd';
import _ from 'lodash';

import * as alertApi from '../../../../../../../../api/alert';
import ConditionsInput from './components/ConditionsInput';

const FormItem = Form.Item;
const { Option } = Select;

const AlertFormModal = (props) => {
  const { visible, selectedProjectId, deviceList, datapoints, selectedAlertId, afterSubmit } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const getAlert = (alertId) => {
    setIsLoading(true);
    alertApi
      .getAlert(alertId)
      .then((res) => res.json())
      .then((json) => {
        form.setFieldsValue({
          name: json.name,
          conditions: json.conditions,
          isActive: json.isActive,
          cooldown: json.cooldown,
        });
        setIsLoading(false);
      });
  };
  const handleSubmit = (values) => {
    setIsLoading(true);
    (selectedAlertId
      ? alertApi.updateAlert(selectedAlertId, values)
      : alertApi.createAlert({ projectId: selectedProjectId, ...values })
    )
      .then((res) => res.json())
      .then((json) => {
        setIsLoading(false);
        afterSubmit();
      });
  };

  useEffect(() => {
    if (visible) {
      if (selectedAlertId !== undefined) {
        getAlert(selectedAlertId);
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
    }
  }, [visible]);

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return (
    <Modal
      centered
      forceRender
      className="FormModal"
      width="60%"
      style={{ pointerEvents: isLoading ? 'none' : '' }}
      maskClosable={!isLoading}
      title={null}
      footer={null}
      {...props}
    >
      <Spin spinning={isLoading} tip="Loading...">
        <Form
          form={form}
          initialValues={{ conditions: _.fill(Array(1), Array(4)), isActive: true }}
          onFinish={(values) => handleSubmit(values)}
        >
          <FormItem
            {...formItemLayout}
            label="Alert Name"
            name="name"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Input />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Conditions"
            name="conditions"
            rules={[
              { required: true, message: 'This field is required.' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const isAllFilled = _.every(
                    _.map(value, (condition) => {
                      return (
                        !_.isUndefined(condition[0]) &&
                        !_.isUndefined(condition[1]) &&
                        !_.isUndefined(condition[2]) &&
                        _.isNumber(condition[3])
                      );
                    })
                  );
                  if (isAllFilled) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Not a valid condition.');
                },
              }),
            ]}
          >
            <ConditionsInput deviceList={deviceList} datapoints={datapoints} />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Active?"
            name="isActive"
            valuePropName="checked"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Switch />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Cooldown Time (mins.)"
            name="cooldown"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <InputNumber
              min={15}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </FormItem>
          <FormItem>
            <Button type="primary" size="large" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AlertFormModal;
