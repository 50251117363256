import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import './style.scss';

const InfoButton = (props) => {
  return (
    <Tooltip className="InfoButton" {...props}>
      <InfoCircleOutlined />
    </Tooltip>
  );
};

export default InfoButton;
