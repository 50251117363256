// User
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const LOGOUT = 'LOGOUT';
export const UPDATE_AVAILABLE_PROJECTS = 'UPDATE_AVAILABLE_PROJECTS';
export const UPDATE_SELECTED_PROJECT = 'UPDATE_SELECTED_PROJECT';
export const UPDATE_AVAILABLE_FLOORS = 'UPDATE_AVAILABLE_FLOORS';
export const UPDATE_SELECTED_FLOOR = 'UPDATE_SELECTED_FLOOR';
export const UPDATE_PROJECT_DATA = 'UPDATE_PROJECT_DATA';
export const UPDATE_PROJECT_DATA_LOADING_STATUS = 'UPDATE_PROJECT_DATA_LOADING_STATUS';
export const UPDATE_FILL_DEVICES = 'UPDATE_FILL_DEVICES';

// Web Config
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR';
export const UPDATE_DATAPOINTS = 'UPDATE_DATAPOINTS';
export const UPDATE_REQUEST_TYPES = 'UPDATE_REQUEST_TYPES';
export const UPDATE_SENSOR_PARAMS = 'UPDATE_SENSOR_PARAMS';
