import React, { useState } from 'react';
import { Button, Checkbox, Form, Modal, Slider, Spin } from 'antd';
import numeral from 'numeral';

import * as guestApi from '../../../../api/guest';
import { getRequestHistory, setRequestHistory } from '../../../../utils/localProfile';
import './style.scss';

const CheckboxGroup = Checkbox.Group;
const FormItem = Form.Item;

const RequestTempChangeModal = (props) => {
  const { projectId, zoneId, currentTemp, afterSubmit } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  const handleSubmit = (values) => {
    const { tempChange, specialNeeds } = values;

    setIsLoading(true);

    guestApi
      .createUserRequest({
        action: 'REQUEST',
        projectId,
        zoneId,
        originalTemp: currentTemp,
        tempChange,
        isSick: specialNeeds.includes('isSick'),
        isWithGuest: specialNeeds.includes('isWithGuest'),
      })
      .then((result) => result.json())
      .then((json) => {
        let newRequests = getRequestHistory();
        if (newRequests[zoneId]) {
          newRequests[zoneId].push(json.id);
        } else {
          newRequests[zoneId] = [json.id];
        }
        setRequestHistory(newRequests);
        setIsLoading(false);
        afterSubmit();
      });
  };

  const marks = {
    '-3': {
      label: '-3°C',
      style: {
        color: '#313695',
      },
    },
    '-2': {
      label: '-2°C',
      style: {
        color: '#4475b4',
      },
    },
    '-1': {
      label: '-1°C',
      style: {
        color: '#74add2',
      },
    },
    0: '0°C',
    1: {
      label: '+1°C',
      style: {
        color: '#f46d43',
      },
    },
    2: {
      label: '+2°C',
      style: {
        color: '#d73027',
      },
    },
    3: {
      label: '+3°C',
      style: {
        color: '#a50026',
      },
    },
  };

  return (
    <Modal
      centered
      forceRender
      className="FormModal RequestTempChangeModal"
      width="60%"
      style={{ pointerEvents: isLoading ? 'none' : '' }}
      maskClosable={!isLoading}
      title={null}
      footer={null}
      {...props}
    >
      <Spin spinning={isLoading} tip="Loading...">
        <Form
          form={form}
          initialValues={{ tempChange: 0, specialNeeds: [] }}
          onFinish={(values) => handleSubmit(values)}
        >
          <FormItem
            {...formItemLayout}
            label="Temperature Change"
            name="tempChange"
            labelAlign="left"
            rules={[{ required: true, message: 'This field is required.' }]}
          >
            <Slider
              marks={marks}
              tipFormatter={(value) => `${value === 0 ? 0 : numeral(value).format('+0')}°C`}
              step={1}
              included={false}
              min={-3}
              max={3}
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Special Needs" name="specialNeeds" labelAlign="left">
            <CheckboxGroup options={[{ label: 'Sick', value: 'isSick' }]} />
          </FormItem>
          <FormItem>
            <Button type="primary" size="large" htmlType="submit">
              Request
            </Button>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default RequestTempChangeModal;
