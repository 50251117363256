import React from 'react';
import { Button, Input, InputNumber, Select } from 'antd';
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';

import './style.scss';

const InputGroup = Input.Group;
const { Option } = Select;

const ConditionsInput = ({ value: conditions, onChange, deviceList, datapoints, ...props }) => {
  const triggerChange = (conditionIdx, colIdx, value) => {
    let newConditions = _.clone(conditions);
    newConditions[conditionIdx][colIdx] = value;
    onChange(newConditions);
  };

  const addCondition = () => {
    let newConditions = _.clone(conditions);
    newConditions.push(Array(4));
    onChange(newConditions);
  };

  const removeCondition = (conditionIdx) => {
    let newConditions = _.clone(conditions);
    _.pullAt(newConditions, [conditionIdx]);
    onChange(newConditions);
  };

  return (
    <div className="ConditionsInput" {...props}>
      {_.map(conditions, (condition, i) => {
        let returnObj = [];
        if (i !== 0) {
          returnObj.push(
            <div className="condition-row-prefix" key={`condition-row-${i}-prefix`}>
              AND
            </div>
          );
        }

        returnObj.push(
          <InputGroup className="condition-row" key={`condition-row-${i}`}>
            <Select
              placeholder="Device"
              dropdownMatchSelectWidth={false}
              style={{ width: '30%', textAlign: 'left' }}
              value={condition[0]}
              onChange={(value) => triggerChange(i, 0, value)}
            >
              {_.map(deviceList, (device) => (
                <Option key={device.value} value={device.value}>
                  {device.label}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Parameters"
              dropdownMatchSelectWidth={false}
              style={{ width: '25%' }}
              value={condition[1]}
              onChange={(value) => triggerChange(i, 1, value)}
            >
              {_.map(datapoints, (datapoint) => (
                <Option key={datapoint.value} value={datapoint.value}>
                  {datapoint.label}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Operator"
              style={{ width: '15%' }}
              dropdownStyle={{ textAlign: 'center' }}
              value={condition[2]}
              onChange={(value) => triggerChange(i, 2, value)}
            >
              <Option key="eq">=</Option>
              <Option key="neq">&ne;</Option>
              <Option key="lt">&lt;</Option>
              <Option key="lte">&le;</Option>
              <Option key="gt">&gt;</Option>
              <Option key="gte">&ge;</Option>
            </Select>
            <InputNumber
              placeholder="Value"
              style={{ width: '15%' }}
              value={condition[3]}
              onChange={(value) => triggerChange(i, 3, value)}
            />
            <CloseCircleFilled className="remove-btn" onClick={() => removeCondition(i)} />
          </InputGroup>
        );

        return returnObj;
      })}
      <Button style={{ width: '100%' }} onClick={() => addCondition()}>
        <PlusOutlined /> Add Condition
      </Button>
    </div>
  );
};

export default ConditionsInput;
