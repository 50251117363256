import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Table, Tabs } from 'antd';
import numeral from 'numeral';
import _ from 'lodash';

import { getWSUrl } from '../../../../../../utils/ws';
import { getJWT } from '../../../../../../utils/jwt';
import * as projectApi from '../../../../../../api/project';
import * as sensorApi from '../../../../../../api/sensor';

import './style.scss';

const { TabPane } = Tabs;

const WS_URL = getWSUrl();

const DataPage = (props) => {
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const projectData = useSelector((state) => state.user.projectData);
  const isLoadingProjectData = useSelector((state) => state.user.isLoadingProjectData);
  const fillDevices = useSelector((state) => state.user.fillDevices);
  const sensorParams = useSelector((state) => state.config.sensorParams);
  const [data, setData] = useState([]);
  const [sensors, setSensors] = useState([]);
  const [sensorData, setSensorData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const getProjectSensors = (projectId) => {
    projectApi
      .getProjectSensors(projectId)
      .then((res) => res.json())
      .then((json) => setSensors(json));
  };

  const getSensorsData = (sensorIds) => {
    sensorApi
      .getSensorsData({ sensor_ids: sensorIds })
      .then((res) => res.json())
      .then((json) => setSensorData(json));
  };

  const updateData = (newDeviceState) => {
    setData((prevData) =>
      _.map(prevData, (room) => {
        return {
          ...room,
          children: _.map(room.children, (device) => {
            let deviceData = device;

            if (device.id === newDeviceState.deviceId) {
              deviceData = {
                ...deviceData,
                ...newDeviceState,
              };
            }

            return deviceData;
          }),
        };
      })
    );
  };

  const valueFormatter = (text, record) => {
    if (record.isDeviceSlot) {
      return text !== undefined ? numeral(text).format('0.[00]') : '-';
    } else {
      return '';
    }
  };

  const vavColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      fixed: 'left',
      width: 150,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => <div className="child-name">{text}</div>,
    },
    {
      title: 'Eco Mode',
      dataIndex: 'ecoMode',
      key: 'ecoMode',
      align: 'center',
      render: (value, record) => {
        if (record.isDeviceSlot) {
          const mapping = [
            {
              label: 'Not Used',
              value: 1,
            },
            {
              label: 'OFF',
              value: 2,
            },
            {
              label: 'ON',
              value: 3,
            },
          ];
          const target = _.find(mapping, { value });
          return value !== undefined ? target.label || value : '-';
        } else {
          return '';
        }
      },
    },
    {
      title: 'Cur. CO2 (ppm)',
      dataIndex: 'spaceCo2',
      key: 'spaceCo2',
      align: 'center',
      render: valueFormatter,
    },
    {
      title: 'Cur. Temp (°C)',
      dataIndex: 'spaceTemp',
      key: 'spaceTemp',
      align: 'center',
      render: valueFormatter,
    },
    {
      title: 'Target Setpoint (°C)',
      dataIndex: 'occCoolingSetpoint',
      key: 'occCoolingSetpoint',
      align: 'center',
      render: valueFormatter,
    },
    {
      title: 'Damper Position (%)',
      dataIndex: 'damper1Feedback',
      key: 'damper1Feedback',
      align: 'center',
      render: valueFormatter,
    },
  ];

  const iaqColumns = _.concat(
    [
      {
        title: 'Name',
        dataIndex: 'id',
        key: 'id',
        align: 'left',
        fixed: 'left',
        width: 150,
        sorter: (a, b) => a.id.localeCompare(b.id),
        render: (value, record) => {
          const targetSensor = _.find(sensors, { id: value }) || {};

          return targetSensor ? (
            <div>
              {targetSensor.zone}
              <br />
              {targetSensor.name}
            </div>
          ) : (
            value
          );
        },
      },
    ],
    _.map(sensorParams, (param) => ({
      title: `${param.label} ${param.unit ? ` (${param.unit})` : ''}`,
      dataIndex: param.value,
      key: param.value,
      align: 'center',
      render: (value, record) => (value !== undefined ? numeral(value).format('0.[00]') : '-'),
    }))
  );

  useDeepCompareEffect(() => {
    if (selectedProjectId) {
      let newExpandedRowKeys = [];
      const ws = new WebSocket(`${WS_URL}?token=${getJWT()}&project_id=${selectedProjectId}`);
      ws.addEventListener('message', (event) => {
        let json = {};
        try {
          json = JSON.parse(event.data);
          if (json.type === 'DeviceUpdate') {
            updateData(json);
          }
        } catch (error) {}
      });

      setData(
        _.flatMap(projectData, (floor) => {
          return _.map(floor.rooms, (room) => {
            const belimoDevices = _.map(room.devices, (device) => ({
              isDeviceSlot: true,
              id: device.id,
              name: device.name,
              cloudId: device.cloudId,
            }));
            const fillDevicesInZone = _.map(fillDevices[room.id], (device) => ({
              isDeviceSlot: true,
              id: device.id,
              name: device.name,
              fillId: device.deviceId,
            }));
            newExpandedRowKeys.push(room.id);

            return {
              id: room.id,
              name: room.name,
              children: _.concat(belimoDevices, fillDevicesInZone),
              isVav: room.type === 'vav',
            };
          });
        })
      );
      setExpandedRowKeys(newExpandedRowKeys);

      getProjectSensors(selectedProjectId);

      return () => {
        ws.close();
      };
    }
  }, [projectData]);

  useDeepCompareEffect(() => {
    if (sensors.length > 0) getSensorsData(_.map(sensors, (sensor) => sensor.id));
  }, [sensors]);

  return (
    <div className="DataPage">
      <div className="main-section">
        <h2>Devices Data (Live)</h2>
        <Tabs defaultActiveKey="vav">
          <TabPane tab="VAV" key="vav">
            <Table
              rowKey={'id'}
              columns={vavColumns}
              size="small"
              expandable={{
                expandedRowKeys,
                onExpand: (expanded, record) => {
                  if (expanded) {
                    setExpandedRowKeys(expandedRowKeys.concat([record.id]));
                  } else {
                    setExpandedRowKeys(expandedRowKeys.filter((item) => item !== record.id));
                  }
                },
              }}
              dataSource={data}
              scroll={{ x: 640, y: '62vh' }}
              pagination={false}
              loading={isLoadingProjectData}
            />
          </TabPane>
          <TabPane tab="IAQ" key="iaq">
            <Table
              rowKey={'id'}
              columns={iaqColumns}
              size="small"
              dataSource={sensorData}
              scroll={{ x: 640, y: '62vh' }}
              pagination={false}
              loading={isLoadingProjectData}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default DataPage;
