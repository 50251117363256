import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect, { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { Button, DatePicker, Input, Layout, Modal, Select } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import * as externalSensorApi from '../../../../../../api/externalSensor';
import * as deviceApi from '../../../../../../api/device';
import * as userApi from '../../../../../../api/user';
import ParameterListFormModal from './components/ParameterListFormModal';
import './style.scss';

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;

const HistoricalDataPage = () => {
  const [timeRange, setTimeRange] = useState([moment().subtract(1, 'days'), moment()]);
  const [selectedZoneId, setSelectedZoneId] = useState();
  const [selectedDeviceId, setSelectedDeviceId] = useState();
  const [selectedDatapointId, setSelectedDatapointId] = useState();
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [externalSensors, setExternalSensors] = useState([]);
  const [parameterLists, setParameterLists] = useState([]);
  const [selectedParameterListId, setSelectedParameterListId] = useState();
  const [isParameterListModalFormVisible, setIsPararmeterModalFormVisible] = useState(false);
  const [parameterListModalFormMode, setParameterListModalFormMode] = useState('CREATE');
  const selectedProjectId = useSelector((state) => state.user.selectedProjectId);
  const datapoints = useSelector((state) => state.config.datapoints);
  const projectData = useSelector((state) => state.user.projectData);
  const zoneList = _.flatMap(projectData, (building) =>
    _.flatMap(building.floors, (floor) =>
      _.flatMap(floor.rooms, (room) => ({
        label: `${building.name}/${floor.name}/${room.name}`,
        value: room.id,
        devices: _.map(room.devices, (device) => ({
          label: device.name,
          value: device.cloudId,
        })),
      }))
    )
  );
  const deviceList = _.flatMap(zoneList, (zone) =>
    _.flatMap(zone.devices, (device) => ({
      zoneId: zone.value,
      deviceId: device.value,
      deviceName: device.label,
    }))
  );
  const selectedZone = selectedZoneId ? _.find(zoneList, { value: selectedZoneId.value }) : {};
  const selectedZoneExternalSensors = selectedZoneId ? _.filter(externalSensors, { zoneId: selectedZoneId.value }) : [];
  const selectedParameterList = selectedParameterListId
    ? _.find(parameterLists, { id: selectedParameterListId.value })
    : {};

  const isSelectedBelimoDevice = selectedDeviceId !== undefined ? selectedDeviceId.value.startsWith('belimo-') : false;
  const isSelectedExternalDevice = selectedDeviceId !== undefined ? selectedDeviceId.value.startsWith('ext-') : false;

  const selectedSeriesLength = selectedSeries.length;
  const rowNumber = selectedSeriesLength % 2 === 1 ? (selectedSeriesLength + 1) / 2 : selectedSeriesLength / 2;

  const getExternalSensors = () => {
    if (selectedProjectId !== undefined) {
      externalSensorApi
        .getExternalSensors({ project_id: selectedProjectId })
        .then((res) => res.json())
        .then((json) => {
          setExternalSensors(json);
        });
    }
  };

  const getDeviceData = (deviceId, datapointId) => {
    deviceApi
      .getDeviceData(deviceId, {
        datapoint_ids: [datapointId],
        from: timeRange[0].toISOString(),
        to: timeRange[1].toISOString(),
      })
      .then((res) => res.json())
      .then((json) => {
        if (json.error) {
          console.error(json.error);
        } else {
          setSeriesData((prevSeresData) =>
            _.map(prevSeresData, (series) => {
              if (series.deviceId === deviceId && series.datapointId === datapointId)
                return {
                  ...series,
                  data: _.map(json.series[0].values, (value) => [value.timestamp, value.value]),
                };
              else return series;
            })
          );
        }
      });
  };

  const getExternalSensorData = (externalSensorId, deviceId, datapointId) => {
    deviceApi
      .getDeviceData(deviceId, {
        datapoint_ids: [datapointId],
        from: timeRange[0].toISOString(),
        to: timeRange[1].toISOString(),
      })
      .then((res) => res.json())
      .then((json) => {
        if (json.error) {
          console.error(json.error);
        } else {
          setSeriesData((prevSeresData) =>
            _.map(prevSeresData, (series) => {
              if (series.externalSensorId === externalSensorId)
                return {
                  ...series,
                  data: _.map(json.series[0].values, (value) => [value.timestamp, value.value]),
                };
              else return series;
            })
          );
        }
      });
  };

  const getParameterLists = () => {
    if (selectedProjectId !== undefined) {
      userApi
        .getParameterLists({ project_id: selectedProjectId })
        .then((res) => res.json())
        .then((json) => {
          setSelectedParameterListId();
          setParameterListsWithTitle(json);
        });
    }
  };

  const getOption = () => {
    var option = {
      title: _.map(seriesData, (series, i) => {
        const height = (100 - rowNumber * 20) / rowNumber;
        const width = selectedSeriesLength % 2 === 1 && i === selectedSeriesLength - 1 ? 88 : 38;

        return {
          x: `${7 + (i % 2 === 0 ? 0 : 48) + width / 2}%`,
          y: `${2 + Math.floor(i / 2) * (height + 20)}%`,
          text: series.title,
          textStyle: {
            fontSize: 14,
          },
          textAlign: 'center',
        };
      }),
      grid: _.map(seriesData, (series, i) => {
        const firstRowAdjustment = rowNumber === 1 ? 5 : 0;
        const height = (100 - rowNumber * 20) / rowNumber - firstRowAdjustment;
        const width = selectedSeriesLength % 2 === 1 && i === selectedSeriesLength - 1 ? 88 : 38;

        return {
          x: `${7 + (i % 2 === 0 ? 0 : 48)}%`,
          y: `${10 + Math.floor(i / 2) * (height + 20) + firstRowAdjustment}%`,
          width: `${width}%`,
          height: `${height}%`,
        };
      }),
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          animation: false,
        },
        formatter: (params) => {
          let res = `${moment(params[0].data[0]).format('YYYY-MM-DD HH:mm')}`;
          res += `<table>`;
          _.forEach(params, (param) => {
            res += `<tr>
              <td>${param.marker}${param.seriesName}</td>
              <td style="padding-left: 15px; text-align:center">${numeral(param.data[1]).format('0[.]00')}</td>
            </tr>`;
          });
          res += `</table>`;
          return res;
        },
      },
      axisPointer: {
        link: { xAxisIndex: 'all' },
      },
      xAxis: _.map(seriesData, (series, i) => ({
        gridIndex: i,
        type: 'time',
        splitLine: {
          show: false,
        },
      })),
      yAxis: _.map(seriesData, (series, i) => ({
        gridIndex: i,
        splitLine: {
          show: false,
        },
      })),
      series: _.map(seriesData, (series, i) => ({
        id: i,
        name: series.title,
        type: 'line',
        xAxisIndex: i,
        yAxisIndex: i,
        showSymbol: false,
        hoverAnimation: false,
        data: series.data,
      })),
    };
    return option;
  };

  const getDeviceLabel = (device) => {
    if (device.type === 'belimo') {
      const targetDevice = _.find(deviceList, { zoneId: device.zoneId, deviceId: device.deviceId });
      return targetDevice ? targetDevice.deviceName : device.deviceId;
    } else if (device.type === 'external') {
      const targetDevice = _.find(externalSensors, { zoneId: device.zoneId, id: device.externalSensorId });
      return targetDevice ? targetDevice.name : device.id;
    }
  };

  const getTitle = (series) => {
    const targetZone = _.find(zoneList, { value: series.zoneId }) || {};
    const targetDatapoint = _.find(datapoints, { value: series.datapointId }) || {};
    const deviceName = getDeviceLabel(series);

    return series.type === 'external'
      ? `${targetZone.label}\n${deviceName}`
      : `${targetZone.label}/${deviceName}\n${targetDatapoint.label}`;
  };

  const setParameterListsWithTitle = (parameterLists) => {
    setParameterLists(
      _.map(parameterLists, (parameterList) => ({
        ...parameterList,
        parameters: _.map(parameterList.parameters, (parameter) => ({
          ...parameter,
          title: getTitle(parameter),
        })),
      }))
    );
  };

  useEffect(() => {
    getExternalSensors();
    getParameterLists();
  }, [selectedProjectId]);
  useDeepCompareEffect(() => {
    setParameterListsWithTitle(parameterLists);
  }, [projectData]);
  useDeepCompareEffect(() => {
    _.forEach(selectedSeries, (series) => {
      if (series.type === 'belimo') {
        getDeviceData(series.deviceId, series.datapointId);
      } else if (series.type === 'external') {
        getExternalSensorData(series.externalSensorId, 'cdead534-cb7f-4de3-ade1-d801927533cf', 'D45EuDN7.66');
      }
    });
  }, [selectedSeries, timeRange]);
  useDeepCompareEffect(() => {
    setSeriesData(
      _.map(selectedSeries, (series) => ({
        ...series,
        xAxis: [],
        data: [],
      }))
    );
  }, [selectedSeries]);
  useDeepCompareEffectNoCheck(() => {
    setSelectedDeviceId();
    setSelectedDatapointId();
  }, [selectedZoneId]);
  useDeepCompareEffectNoCheck(() => {
    if (isSelectedExternalDevice) {
      setSelectedDatapointId();
    }
  }, [selectedDeviceId]);

  return (
    <Layout>
      <div className="HistoricalDataPage">
        <div className="main-section">
          <div className="time-section">
            Date Range: <RangePicker value={timeRange} onChange={(dates) => setTimeRange(dates)} />
          </div>
          <div className="series-section">
            <Select
              labelInValue
              placeholder="Zone"
              dropdownMatchSelectWidth={false}
              value={selectedZoneId}
              onChange={(value) => setSelectedZoneId(value)}
            >
              {_.map(zoneList, (zone) => (
                <Option key={zone.value} value={zone.value}>
                  {zone.label}
                </Option>
              ))}
            </Select>{' '}
            <Select
              labelInValue
              placeholder="Device"
              dropdownMatchSelectWidth={false}
              value={selectedDeviceId}
              onChange={(value) => setSelectedDeviceId(value)}
              disabled={_.isEmpty(selectedZoneId)}
            >
              <OptGroup label="Belimo Devices">
                {_.map(selectedZone.devices, (device) => (
                  <Option key={device.value} value={`belimo-${device.value}`}>
                    {device.label}
                  </Option>
                ))}
              </OptGroup>
              {selectedZoneExternalSensors.length > 0 && (
                <OptGroup label="External Devices">
                  {_.map(selectedZoneExternalSensors, (externalSensor) => (
                    <Option key={externalSensor.id} value={`ext-${externalSensor.id}`}>
                      {externalSensor.name}
                    </Option>
                  ))}
                </OptGroup>
              )}
            </Select>{' '}
            <Select
              labelInValue
              placeholder="Parameter"
              dropdownMatchSelectWidth={false}
              value={selectedDatapointId}
              onChange={(value) => setSelectedDatapointId(value)}
              disabled={_.isEmpty(selectedDeviceId) || !isSelectedBelimoDevice}
            >
              {_.map(datapoints, (datapoint) => (
                <Option key={datapoint.value} value={datapoint.value}>
                  {datapoint.label}
                </Option>
              ))}
            </Select>{' '}
            <Button
              onClick={() => {
                if (selectedSeriesLength < 8) {
                  setSelectedSeries((prevSelectedSeries) => {
                    const newSelectedSeries = _.cloneDeep(prevSelectedSeries);
                    if (isSelectedBelimoDevice) {
                      newSelectedSeries.push({
                        type: 'belimo',
                        title: `${selectedZoneId.label}/${selectedDeviceId.label}\n${selectedDatapointId.label}`,
                        zoneId: selectedZoneId.value,
                        deviceId: selectedDeviceId.value.replace('belimo-', ''),
                        datapointId: selectedDatapointId.value,
                      });
                    } else if (isSelectedExternalDevice) {
                      newSelectedSeries.push({
                        type: 'external',
                        title: `${selectedZoneId.label}\n${selectedDeviceId.label}`,
                        zoneId: selectedZoneId.value,
                        externalSensorId: selectedDeviceId.value.replace('ext-', ''),
                      });
                    }
                    return newSelectedSeries;
                  });
                }
              }}
              disabled={
                !(
                  selectedSeriesLength === 8 ||
                  (isSelectedBelimoDevice && !_.isEmpty(selectedDatapointId)) ||
                  isSelectedExternalDevice
                )
              }
            >
              Add
            </Button>
            <div className="series-list">
              Selected Series: ({`${selectedSeriesLength}/8`})
              <div>
                {_.map(selectedSeries, (series, i) => (
                  <div className="series-list-item" key={`series-${i}`}>
                    {series.title}{' '}
                    <CloseOutlined
                      onClick={() => {
                        setSelectedSeries((prevSelectedSeries) => {
                          const newSelectedSeries = _.cloneDeep(prevSelectedSeries);
                          newSelectedSeries.splice(i, 1);
                          return newSelectedSeries;
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <Select
                labelInValue
                placeholder="Series Config"
                dropdownMatchSelectWidth={false}
                value={selectedParameterListId}
                onChange={(value) => setSelectedParameterListId(value)}
              >
                {_.map(parameterLists, (parameterList) => (
                  <Option key={parameterList.id} value={parameterList.id}>
                    {parameterList.name}
                  </Option>
                ))}
              </Select>{' '}
              <Button
                onClick={() => {
                  setIsPararmeterModalFormVisible(true);
                  setParameterListModalFormMode('CREATE');
                }}
              >
                Create
              </Button>{' '}
              <Button
                onClick={() => {
                  setIsPararmeterModalFormVisible(true);
                  setParameterListModalFormMode('UPDATE');
                }}
                disabled={!selectedParameterListId}
              >
                Update
              </Button>{' '}
              <Button
                disabled={!selectedParameterListId}
                onClick={() => setSelectedSeries(selectedParameterList.parameters)}
              >
                Load
              </Button>
            </div>
          </div>
          {<ReactEcharts style={{ minHeight: '500px', height: '70vh' }} notMerge={true} option={getOption()} />}
        </div>
      </div>
      <ParameterListFormModal
        selectedProjectId={selectedProjectId}
        selectedParameterList={selectedParameterList}
        selectedParameters={selectedSeries}
        formMode={parameterListModalFormMode}
        visible={isParameterListModalFormVisible}
        onCancel={() => setIsPararmeterModalFormVisible(false)}
        afterSubmit={() => {
          setIsPararmeterModalFormVisible(false);
          getParameterLists();
        }}
      />
    </Layout>
  );
};

export default HistoricalDataPage;
