import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ExclamationCircleOutlined, FundOutlined, PartitionOutlined, StockOutlined } from '@ant-design/icons';

import { collapseSidebar } from '../../../../redux/actions/config';

const { Content, Sider } = Layout;
const MenuItem = Menu.Item;

const FMPage = (props) => {
  const dispatch = useDispatch();
  const { children, location } = props;
  const collapsed = useSelector((state) => state.config.isSidebarCollapsed);
  const match = matchPath(location.pathname, {
    path: '/dashboard/:projectId/:dashboardMode/:dashboardPage',
    exact: true,
    strict: false,
  });
  const { projectId } = match ? match.params : {};

  return (
    <Layout>
      <Sider collapsible collapsed={collapsed} onCollapse={() => dispatch(collapseSidebar(!collapsed))}>
        <div className="logo" />
        <Menu selectedKeys={[location.pathname]} mode="inline">
          <MenuItem key={`/dashboard/${projectId}/fm/data`}>
            <NavLink to={`/dashboard/${projectId}/fm/data`}>
              <StockOutlined />
              <span>Live Data</span>
            </NavLink>
          </MenuItem>
          <MenuItem key={`/dashboard/${projectId}/fm/historical-data`} disabled>
            <NavLink to={`/dashboard/${projectId}/fm/historical-data`}>
              <FundOutlined />
              <span>Historical Data</span>
            </NavLink>
          </MenuItem>
          <MenuItem key={`/dashboard/${projectId}/fm/alerts`} disabled>
            <NavLink to={`/dashboard/${projectId}/fm/alerts`}>
              <ExclamationCircleOutlined />
              <span>Alerts</span>
            </NavLink>
          </MenuItem>
          {/* <MenuItem key={`/dashboard/${projectId}/fm/external-sensors`}>
            <NavLink to={`/dashboard/${projectId}/fm/external-sensors`}>
              <PartitionOutlined />
              <span>Exteral Sensors</span>
            </NavLink>
          </MenuItem> */}
        </Menu>
      </Sider>
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default FMPage;
