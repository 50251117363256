import React from 'react';
import { Popconfirm } from 'antd';

const IrreversiblePopconfirmWrapper = (props) => {
  const { children, ...rest } = props;

  return (
    <Popconfirm title="This action is irreversible, are you sure?" okText="Yes" cancelText="No" {...rest}>
      {children}
    </Popconfirm>
  );
};

export default IrreversiblePopconfirmWrapper;
