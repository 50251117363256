import { COLLAPSE_SIDEBAR, UPDATE_DATAPOINTS, UPDATE_REQUEST_TYPES, UPDATE_SENSOR_PARAMS } from '../actionTypes';

const INITIAL_STATE = {
  isSidebarCollapsed: window.innerWidth <= 480,
  datapoints: [],
  requestTypes: [],
  sensorParams: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COLLAPSE_SIDEBAR:
      return {
        ...state,
        isSidebarCollapsed: action.payload,
      };
    case UPDATE_DATAPOINTS:
      return {
        ...state,
        datapoints: action.payload,
      };
    case UPDATE_REQUEST_TYPES:
      return {
        ...state,
        requestTypes: action.payload,
      };
    case UPDATE_SENSOR_PARAMS:
      return {
        ...state,
        sensorParams: action.payload,
      };
    default:
      return state;
  }
};
